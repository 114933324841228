<template>
  <div class="line" v-if="user">
      <main class="main mb-100">
        <article class="article">
          <img src="/Group_675.svg" style='height:200px; margin-left:-30px;' class='' alt="">
          <div class="article__content content">
            <h2 class="article__title bold">
                  Сервис для отслеживания судебной практики
                  
              </h2>
            <h5 class="">Находит судебные акты по ключевым словам и присылает их вам в момент публикации в судебных базах</h5>
          </div>
          
        </article>
        <el-row class="mt-3">
          <el-button @click="add_new"  class="no-select black-btn"  round icon="el-icon-plus" ></el-button>
          <el-popconfirm
            v-if='user_db.telegram'
            confirmButtonText='Да'
            cancelButtonText='Нет'
            icon="el-icon-info"
            iconColor="red"
            
            @confirm="toTg()"
            title="Привязать Telegram и получать уведомление через мессенджер?"
          >
          <template #reference>
            <el-button   round icon="el-icon-s-promotion" type='warning' class="no-select " ></el-button>
          </template>
          </el-popconfirm>
          <el-popconfirm
            v-else
            confirmButtonText='Да'
            cancelButtonText='Нет'
            icon="el-icon-info"
            iconColor="red"
            
            @confirm="toTg()"
            title="Привязать Telegram и получать уведомление через мессенджер?"
          >
          <template #reference>
            <el-button   round icon="el-icon-s-promotion" class="no-select black-btn" ></el-button>
          </template>
          </el-popconfirm>
          <el-button @click="to_tarif"  class="no-select black-btn"  round icon="el-icon-bank-card" ></el-button>
          <el-popconfirm
            confirmButtonText='Да'
            cancelButtonText='Нет'
            icon="el-icon-info"
            iconColor="red"
            @confirm="logOut()"
            title="Вы действительно хотите выйти?"
          >
          <template #reference>
            <el-button type="warning" round icon="el-icon-switch-button" ></el-button>
          </template>
          </el-popconfirm>

        </el-row>
        <el-row class=" mt-10" v-if="user_db.subscribtions.length>0">
        <table>
          <thead>
            <tr>
              <th scope="col">Фраза</th>
              <th scope="col">Суд</th>
              <th scope="col">Вид спора</th>
              <th scope="col">Категория спора</th>
              <th scope="col">Инфо</th>
            </tr>
          </thead>
          <tbody>
            
            <tr v-for='row in user_db.subscribtions'>
              <td data-label="Фраза">
                 
                  {{row.text}} 
              
              </td>

              <td data-label="Суд">
                  <small  v-if='row.filters.court>0'  v-for='court in row.filters.court'>
                     {{ court }}  <br>
                  </small>
                   <small v-else>
                    -
                  </small>

              </td>
              <td class='truncate' data-label="Вид ">
                   <small v-if='row.filters.types.length>0'  v-for='type in row.filters.types' >
                     {{ type }}  <br>
                  </small>
                  <small v-else>
                    -
                  </small>
              </td>
              <td class='truncate' data-label="Категория ">
                  <small v-if='row.filters.categories.length>0'  v-for='cat in row.filters.categories'>
                     {{ cat }}     <br>
                  </small>
                   <small v-else>
                    -
                  </small>
              </td>
            

              <td data-label="Инфо" v-if='row.filters.info'>{{row.filters.info}}

                <el-popconfirm
                    confirmButtonText='Да'
                    cancelButtonText='Нет'
                    icon="el-icon-info"
                    iconColor="red"
                    
                    @confirm="delete_subscribe(row.id)"
                    title="Действительно удалить подписку?"
                  >
                  <template #reference>
                    <el-button  > <i  class="el-icon-delete" type='danger'> </i></el-button >
                  </template>
                  </el-popconfirm>
              </td>
              <td data-label="Инфо" v-else>
                
                <el-popconfirm
                    confirmButtonText='Да'
                    cancelButtonText='Нет'
                    icon="el-icon-info"
                    iconColor="red"
                    
                    @confirm="delete_subscribe(row.id)"
                    title="Действительно удалить подписку?"
                  >
                  <template #reference>
                    <el-button  > <i  class="el-icon-delete" type='danger'> </i></el-button >
                  </template>
                  </el-popconfirm>

              </td>
              
            </tr>
             
            
          </tbody>
        </table>
    </el-row>

    <el-empty v-else description="Подписок пока нет">
    </el-empty>
      </main>

      

    
    

  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      user: null,
      inputVisibleStop: false,
      inputValueStop: '',
      table_data:[ ]
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
        this.$router.push('/login');
      }
    });
  },
 
  computed: {
    user_db () {
      return this.$store.getters.GETTERS_USER_INFO
    }
  },
  watch:{
    user_db(val){
      this.table_data = val.subscribtions
    }
  },
  methods: {
    delete_subscribe(id){
      var data = {id:id}
      data['uid'] = this.user.uid;
      this.$store.dispatch('DELETE_SUB',data)
      .then(res => {
            this.$store.dispatch('GET_USER', this.user)
      })
      .catch(e => {
          alert('ошибка в программе, напишите в чат в правом нижнем углу')
      })
      

    },
    handleInputConfirm() {
        let inputValueStop = this.inputValueStop;
        if (inputValueStop) {
          alert("пока нельзя добавлять стоп-слова")
        }
        this.inputVisibleStop = false;
        this.inputValueStop = '';
      },
    add_new(){
       this.$router.push('/add-new');
    },
    to_tarif(){
      window.open('https://kazna.tech/tarif-word','_blank')
    },
    showInput() {
        this.inputVisibleStop = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },
    handleClose(){
      alert('сейчас нельзя удалять метки')
    },
    toTg(){
      if (this.user.uid)
      window.open('tg://resolve?domain=wordcourt_bot&start='+this.user.uid)
      else alert('ошибка подключения')
    },
    logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          firebase.auth().onAuthStateChanged(() => {
            this.$router.push('/login');
          });
        });
    },
  },
};
</script>



<style lang="scss" scoped>
@import "../assets/gov/css/accessibility.css";
@import "../assets/gov/css/interface.css";
@import "../assets/gov/css/license.css";
@import "../assets/gov/css/main.css";
@import "../assets/gov/css/userpic.css";
.header_title{
    color: white !important;
}
</style>

<style lang="scss">
  .black-btn{
    background-color:black !important;
    color: white;
  }
  .black-btn:hover{
    background-color:rgb(40, 40, 40) !important;
    color: white;
  }

body{
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Arbutus+Slab|Open+Sans&display=swap");
body {
  color: #495456;
  margin: 72px;
}
.bold{
  font-weight: 900;
}
h2 {
  font-weight: normal;
  color: #000;
  line-height: 1.25;
}

p {
  position: absolute;
  bottom: 0;
  font-size: 14px;
}


.card {
  cursor: pointer;
  padding-bottom: 300px;
  --offset-multiplier: 4px;
  transition: transform 0.6s ease;
  --translate: 0;
  transform: translate(var(--translate), var(--translate));
}
.card:hover {
  --offset-multiplier: 6px;
}
.card:hover {
  transition: transform 0.3s ease;
}

.child {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  position: absolute;
  width: 90%;
  margin-left:5%;
  height: 300px;
  padding: 0 16px;
  background: #fff;
  border-radius: 6px;
  transition: inherit;
  --translate: calc(var(--offset) * var(--offset-multiplier));
  transform: translate(var(--translate), var(--translate));
  z-index: 5;
}
.child:nth-child(1) {
  --offset: 0;
  z-index: 4;
}
.child:nth-child(2) {
  --offset: 1;
  z-index: 3;
}
.child:nth-child(3) {
  --offset: 2;
  z-index: 2;
}
.child:nth-child(4) {
  --offset: 3;
  z-index: 1;
}
.child:nth-child(5) {
  --offset: 4;
  z-index: 0;
}
</style>

<style lang="scss">

:root {
  --icon-size: 28px;
  --background-color: rgb(204, 220, 255);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.table {
  justify-content: center;
  align-items: center;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

$font-size: 12px;
$time: 0.3s;
$border: 1px;
$border-radius: 5px;
$border-color: #5fef8d;
$chip-color: #3e5864;
$morph-chip-color: #5fef8d;
$selected-bg: rgba(0, 0, 0, 0.1);

@mixin border() {
  border: $border solid $border-color;
  border-right: none;
  &:last-child {
    border-right: $border solid $border-color;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
}
.radius {
  border-radius: $border-radius;

  .tab:active::before {
    border-radius: $border-radius !important;
  }
}

.border .tab {
  @include border();
  &:active::before {
    border-radius: inherit !important;
  }
}

.tab-bar {
  padding: 5px;
  display: flex;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  .tab {
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    width: 70px;
    height: 70px;
    background: inherit;
    padding: 5px;
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all $time;

    &::before {
      position: absolute;
      content: '';
      width: 26%;
      height: 13%;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      border-bottom: none;
      background-color: $chip-color;
      bottom: -8px;
      opacity: 0;
      transition: all $time ease-in-out;
    }

    &:active::before {
      width: 100%;
      height: 100%;
      background-color: $morph-chip-color;
      border-radius: 0;
    }

    &:hover::before {
      opacity: 1;
      bottom: 0px;
    }

    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      color: inherit;
      font-size: $font-size;
      opacity: 0;
      transition: all $time ease-in-out;
    }

    &:hover {
      &::after {
        opacity: 1;
        top: 6px;
      }
      padding-top: 15px;
    }

    &.selected {
      background-color: $selected-bg;
      border-radius: inherit;

      &::after {
        opacity: 1;
        top: 6px;
      }
      &::before {
        opacity: 1;
        bottom: 0px;
      }
      padding-top: 15px;
    }

    .icon {
      color: inherit;
      z-index: 10;
      font-size: 2 * $font-size;
      display: inherit;

      img {
        margin: auto;
        height: 2 * $font-size;
      }
    }
  }
}
.ma-3{
  margin:15px;
}
.ml-10{
  margin-left:30px;
}
.mt-3{
  margin-top:30px;
}
.mb-3{
  margin-bottom:30px;
}
.mb-100{
  padding-bottom:100px;
}
.mb-20{
  margin-bottom:50px;
  margin-top:30px;
}
.ma-1{
  margin:5px;
}

body {
  overflow: scroll;
  height: 95vh;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
}
:root {
  --icon-size: 28px;
  --background-color: rgb(204, 220, 255);
}
.my-alert{
  border-left:  3px solid;
  border-radius: 0px;
}
.mt-10{
  margin-top: 50px;
}
.no-select{
  -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  resize: horizontal;

}
</style>

<style lang="scss">
.line{
  border-top: 3px solid black;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
  border-radius: 5px;

}

table th,
table td {
  padding: .625em;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>